import React from 'react'
import { Card, Header, Icon, Image } from 'semantic-ui-react';
import example from "../../assets/RemoteControl.jpg";
import example2 from "../../assets/Fan_Wiring.jpeg";
import example3 from "../../assets/Fan_Remote_HA.png";
import example4 from "../../assets/Fan_Remote_HA2.png";
import { Link } from 'react-router-dom';

const FanRemotePage: React.FC = () => {
    return (
        <div className="page-container" style={{ backgroundColor: '#282c34', minHeight: '100vh', padding: '20px' }}>
            <Card centered style={{marginTop: '25px', width: '80%', backgroundColor: '#333', color: 'white'}}>
                <Card.Content textAlign='center' header='Fan Remote Project' style={{ backgroundColor: '#fff', color: 'white' }} />
                <Card.Description style={{ color: 'white', padding: '15px' }}>
                    <p>
                    This personal project was designed to provide convenient and smart control over two ceiling fans in my home using a modified RF remote. By repurposing the remote control, I was able to integrate the fans into my home automation system, making them accessible through modern smart devices.
                    </p>
                    <p>
                    The core of the system is a Raspberry Pi Zero W, which interfaces with the modified remote to send commands to the fans. The application controlling the Raspberry Pi was written in Python, a skill I developed through a self-guided Udemy course. This project served as a practical way to apply and enhance my programming knowledge, focusing on automation and IoT (Internet of Things) technologies.
                    </p>
                    <p>
                    The fans are integrated with <Link to="https://www.home-assistant.io/" style={{ color: '#47C21F' }}>
    Home Assistant
</Link> , a popular home automation platform, enabling seamless control through a smartphone app and voice commands via Google Assistant. Communication between the Raspberry Pi and Home Assistant is managed using MQTT, a lightweight messaging protocol ideal for IoT applications.
                    </p>
                    <p>
                    This project not only enhanced the functionality of my home but also allowed me to explore new areas of technology, such as hardware integration, Python programming, and IoT communication protocols. It's an example of how creative problem-solving and accessible tools can bring smart home ideas to life.
                    </p>
                </Card.Description>
                <Card.Content style={{ backgroundColor: '#222', color: 'white' }}>Project written in Python 3</Card.Content>
                <Card.Content style={{ backgroundColor: '#222', color: 'white' }}>
                    <Icon name='github' size='large'/>
                    <a
                        className="App-link"
                        href="https://github.com/dapowers87/FanRemoteControl"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        GitHub Repository
                    </a>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#282c34', color: 'white' }}>
                    <Header as='h3' style={{ color: 'white' }}>Raspberry Pi / Remote Control Solution</Header>
                    <Image bordered fluid src={example} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#282c34', color: 'white' }}>
                    <Header as='h3' style={{ color: 'white' }}>Home Assistant Integration</Header>
                    <Image bordered fluid src={example4} size="large"/>
                    <Image bordered fluid src={example3} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#282c34', color: 'white' }}>
                    <Header as='h3' style={{ color: 'white' }}>Prototype</Header>
                    <Image bordered fluid src={example2} size="large"/>
                </Card.Content>
            </Card>
        </div>
    )
}

export default FanRemotePage;