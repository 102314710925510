import React from 'react'
import { Card, Header, Icon, Image } from 'semantic-ui-react';
import example from "../../assets/ratgdo_breadboard.jpg";
import example2 from "../../assets/ratgdo_installed.jpg";
import { Link } from 'react-router-dom';

const RatgdoPage: React.FC = () => {
    return (
        <div className="page-container" style={{ backgroundColor: '#282c34', minHeight: '100vh', padding: '20px' }}>
            <Card centered style={{marginTop: '25px', width: '80%', backgroundColor: '#333', color: 'white'}}>
                <Card.Content textAlign='center' header='Ratgdo' style={{ backgroundColor: '#fff', color: 'black' }} />
                <Card.Description style={{ color: 'white', padding: '15px' }}>
                    <p>
                    In this project, I wired a RatGDO (ESP-based Garage Door Opener)—a Wi-Fi-enabled control board designed to integrate with garage door openers, enabling local network control and real-time status updates— directly to my existing garage door opener. The setup involved connecting the RatGDO relay to the garage door opener's terminals, enabling remote control capability.
                    </p>



                    <p>
                    Integration into&#160;<Link to="https://www.home-assistant.io/" style={{ color: '#47C21F' }}>
    Home Assistant
</Link> was achieved through MQTT, allowing me to monitor and operate the garage door via the Home Assistant dashboard and through voice commands with Google Assistant.
                    </p>
                    <p>
                    This configuration provides a reliable, responsive, and secure method of automating my garage door, enhancing convenience and home automation capabilities.
                    </p>
                </Card.Description>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
                        <Image src={example} size='medium' rounded />
                        <Image src={example2} size='large' rounded />
                    </div>
                {/* <Card.Content textAlign='center' style={{ backgroundColor: '#fff', color: 'black' }}>
                    <Header as='h3' style={{ color: 'black' }}>Raspberry Pi / Remote Control Solution</Header>
                    <Image bordered fluid src={example} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#fff', color: 'black' }}>
                    <Header as='h3' style={{ color: 'black' }}>Home Assistant Integration</Header>
                    <Image bordered fluid src={example4} size="large"/>
                    <Image bordered fluid src={example3} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#fff', color: 'black' }}>
                    <Header as='h3' style={{ color: 'black' }}>Prototype</Header>
                    <Image bordered fluid src={example2} size="large"/>
                </Card.Content> */}
            </Card>
        </div>
    )
}

export default RatgdoPage;