import React, { useEffect, useState } from "react";
import { Menu, Container } from "semantic-ui-react";
import { NavLink, Link, useLocation } from "react-router-dom";

const NavBar: React.FC = () => {
  let location = useLocation();

  const [activePage, setActivePage] = useState<string>(location.pathname);

  useEffect(() => {
      setActivePage(location.pathname);
  }, [setActivePage, location.pathname]);


  return (
    <div>
      <Menu fixed="top" inverted style={{overflow: 'auto'}}>
        <Container>
          <Menu.Item header as={NavLink} exact to='/'>
            Home
          </Menu.Item>
          <Menu.Item name="Habit Tracker" as={Link} to='/HabitTracker' active={activePage === '/HabitTracker'} />
          {/* <Menu.Item name="Music Search" as={Link} to='/MusicPage' active={activePage === '/MusicPage'} /> */}
          <Menu.Item name="Fan Remote" as={Link} to='/FanRemote' active={activePage === '/FanRemote'} />
          <Menu.Item name="Garage Door Controller" as={Link} to='/Ratgdo' active={activePage === '/Ratgdo'} />
          <Menu.Item name="Chicken Coop Door" as={Link} to='/ChickenCoop' active={activePage === '/ChickenCoop'} />
          {/* <Menu.Item name="Values Lister"  as={Link} to='/ValuesLister' active={activePage === '/ValuesLister'} /> */}
          {/* <Menu.Item content="Mongo Example: Person List"  as={Link} to='/Persons' active={activePage.startsWith('/Persons')} /> */}
          <Menu.Item name="Website Diagram"  as={Link} to='/Diagram' active={activePage === '/Diagram'} />
        </Container>
      </Menu>
    </div>
  );
};

export default NavBar;
