import React from 'react'
import { Card, Header, Icon, Image } from 'semantic-ui-react';
import example from "../../assets/ChickenCoopDoor.jpg";
import example2 from "../../assets/ChickenCoop_HA.png";
import example3 from "../../assets/ChickenGang.jpg";
import example4 from "../../assets/chicken_nest_cam_pic.jpg";
import { Link } from 'react-router-dom';

const ChickenCoopPage: React.FC = () => {
    return (
        <div className="page-container" style={{ backgroundColor: '#282c34', minHeight: '100vh', padding: '20px' }}>
            <Card centered style={{marginTop: '25px', width: '80%', backgroundColor: '#333', color: 'white'}}>
                <Card.Content textAlign='center' header='Chicken Coop Door Project' style={{ backgroundColor: '#fff', color: 'white' }} />
                <Card.Description style={{ color: 'white', padding: '15px' }}>
                    <p>
                    This personal project was designed to automate the operation of a custom-built chicken coop door, enhancing convenience and ensuring the safety of the chickens. The system uses a Raspberry Pi 4 to control the door's motor and monitor its status, detecting whether the door is open or closed at any given time.
                    </p>
                    <p>
                    I developed the control script in Python, tackling the challenge as a beginner in the language. While functional, the code reflects my initial learning curve and lacks the polished practices seen in my later projects, such as the <Link to="/FanRemotePage" style={{ color: '#47C21F' }}>
                        Fan Remote project
                    </Link> system. The fan project serves as a more refined example of my growth in Python development, incorporating cleaner code structure and best practices.
                    .
                    </p>
                    <p>
                    To enhance usability, the chicken coop door is integrated with Home Assistant, a powerful home automation platform. This integration allows for remote control via a smartphone app and voice commands through Google Assistant. Communication between the Raspberry Pi and <Link to="https://www.home-assistant.io/" style={{ color: '#47C21F' }}>
                        Home Assistant
                    </Link>

                    </p>
                    <p>
                    This project not only simplifies daily management of the chicken coop but also marks an important step in my journey into home automation and IoT technologies. It showcases how creative problem-solving and practical learning can bring smart solutions to everyday challenges while highlighting my progression as a developer.
                    </p>
                </Card.Description>
                <Card.Content style={{ backgroundColor: '#222', color: 'white' }}>Project written in Python 2</Card.Content>
                <Card.Content style={{ backgroundColor: '#222', color: 'white' }}>
                    <Icon name='github' size='large'/>
                    <a
                        className="App-link"
                        href="https://github.com/dapowers87/ChickenCoop"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        GitHub Repository
                    </a>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#222', color: 'white' }}>
                    <Header as='h3' style={{ color: 'white' }}>Home Assistant Integration:</Header>
                    <Image bordered fluid src={example2} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#222', color: 'white' }}>
                    <Header as='h3' style={{ color: 'white' }}>Interior Chicken Nest Camera</Header>
                    <Image bordered fluid src={example4} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#222', color: 'white' }}>
                    <Header as='h3' style={{ color: 'white' }}>Chicken Coop Door</Header>
                    <Image bordered fluid src={example} size="medium"/>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#222', color: 'white' }}>
                    <div className="embed-container">
                        <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/zWPRJoo6xOg?si=0xHXN40bnhJ_k9VR" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </div>
                </Card.Content>
                <Card.Content textAlign='center' style={{ backgroundColor: '#222', color: 'white' }}>
                    <Header as='h3' style={{ color: 'white' }}>The Crew</Header>
                    <Image bordered fluid src={example3} size="huge"/>
                </Card.Content>
            </Card>
        </div>
    )
}

export default ChickenCoopPage;